import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API from './repository'
import { getCampaignById } from './index'
import {
  setErrorGlobalMessage,
  setSuccessGlobalMessage,
} from '../../../core/ui/ui.slice'
import { handleError } from '../../../helpers/handleError'
import { CampaignStatusCode } from './constants'
import { selectUserDataById } from './selectors'

export const fetchCampaigns = createAsyncThunk('Campaigns/fetchCampaigns', () =>
  API.fetchAllCampaigns()
)

export const updateCampaignUser = createAsyncThunk(
  'Campaigns/updateCampaignsUser',
  async ({ campaignId, userId }, thunkAPI) => {
    try {
      const oldCampaign = getCampaignById(thunkAPI.getState(), campaignId)
      if (oldCampaign.userId !== userId) {
        // update user
        await API.assignSelectedUser(campaignId, userId)
      }
      const user = selectUserDataById(thunkAPI.getState(), userId)
      thunkAPI.dispatch(setSuccessGlobalMessage())
      // returns the updated user
      return { id: campaignId, changes: { userId, user } }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const updateCampaignStatus = createAsyncThunk(
  'Campaigns/updateCampaignStatus',
  async ({ campaignId }, thunkAPI) => {
    try {
      // update campaign status
      await API.cancelCampaign(campaignId)
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: campaignId,
        changes: { manualStatus: CampaignStatusCode.Canceled },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)
