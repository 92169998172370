/* import external modules */
import React from 'react'

/* import internal modules */
import { ImgContent } from './styles'

/**
 *  @description This functional component is a Image
 *  @version 1.0.0
 *  @since 19/04/2020
 *  @author Yadurani Lopez <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Img
 *  @returns {Component} Returns components Img
 **/

const Img = ({ src, width, height, alt }) => {
  return <ImgContent src={src} width={width} height={height} alt={alt} />
}

export default Img
